import {Component} from '@angular/core';
import { InteractionEventService } from '../interaction-event.service';
import { ViewerBindingService } from '../viewer-binding.service';
import { SimpleModelTracking } from '../babylon-viewer/babylon-viewer.component';
import {HelperTextService} from '../helper-text.service';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  public submitURL = '';
  public chosenLanguage = 'de';
  public languages = ['en', 'de'];

  constructor(private interactionEventService: InteractionEventService, private viewerBindingService: ViewerBindingService,
              public helperTextService: HelperTextService, public translate: TranslateService, private sanitizer: DomSanitizer) {
    this.submitURL = window['modularStorageSubmitURL'];
    
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // look up the "lang" url parameter and choose the language based on that
    let search = window.location.search;
    let langIndex = search.indexOf("lang=");
    let lang = (langIndex >= 0) ? search.substr(langIndex + 5, 2) : this.chosenLanguage;
    this.languageChosen(lang);
  }

  activeModels(): SimpleModelTracking[] {
    return this.viewerBindingService.viewer.placedModels() || [];
  }

  currentProductImage(): string {
    return this.viewerBindingService.viewer.currentProductImageBase64;
  }

  onSubmit(e) {
    e.target.submit();
  }

  reset() {
    this.interactionEventService.notify('reset');
  }
  
  languageChosen(lang) {
    this.chosenLanguage = lang;
    this.translate.use(this.chosenLanguage);
    let dd = document.getElementById("languageDropdown");
    if (dd) {
      dd.style.display = "none";
    }
  }
  
  sanitize(style: string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
  
  toggleLanguageDropdown() {
    let dd = document.getElementById("languageDropdown");
    dd.style.display = (dd.style.display === "none") ? "block" : "none";
  }
  
  saveConfiguration() {
    let config = this.viewerBindingService.viewer.getConfiguration();
    localStorage["mbk12config"] = JSON.stringify(config);
  }
  
  loadConfiguration() {
    if (localStorage["mbk12config"]) {
      let config = JSON.parse(localStorage["mbk12config"]);
      this.viewerBindingService.viewer.setConfiguration(config);
    }
  }
  
  undo() {
    this.viewerBindingService.viewer.undo();
  }
  
  redo() {
    this.viewerBindingService.viewer.redo();
  }
}
